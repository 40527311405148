import {PatchOperation, WorkType} from "@co-common-libs/resources";
import {DecimalField, SelectColor} from "@co-frontend-libs/components";
import {ConnectedDepartmentDialog} from "@co-frontend-libs/connected-components";
import {actions, getCurrentRole, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  Switch,
  useTheme,
} from "@material-ui/core";
import {
  getDepartmentName,
  useEventTargetCheckedCallback,
  useEventTargetCheckedUpdater,
} from "app-utils";
import {hasExternalId} from "external-id-utils";
import CloseIcon from "mdi-react/CloseIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {WorkTypeCreateEditDialog} from "./work-type-create-edit-dialog";
import {WorkTypeCreateEditInternalDialog} from "./work-type-create-edit-internal-dialog";

const messages = defineMessages({
  active: {defaultMessage: "Aktiv"},
  disallowMachineUse: {
    defaultMessage: "Tillader ikke maskiner",
  },
  editWorkType: {
    defaultMessage: "Redigér arbejdsområde",
  },
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: {
    defaultMessage:
      "Hvis der er varer på opgaven, så skal mindst én af disse være udfyldt med større end 0",
  },
  onlyForExtraTimers: {
    defaultMessage: "Anvendes kun til ekstra-tidsknapper",
  },
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: {
    defaultMessage: "Kræv at mindst én valgfri prislinje er udfyldt med en værdi større end 0",
  },
  requireAtLeastOneProductUseGreaterThanZero: {
    defaultMessage:
      "Kræv at der er tilføjet mindst én vare på opgaven og at denne er udfyldt med en værdi større end 0",
  },
  requireField: {
    defaultMessage: "Kræver mark",
  },
  requireMachineUse: {
    defaultMessage: "Kræver maskine",
  },
  requirePhotoOnTaskCompletion: {
    defaultMessage: "Kræver foto når opgave udføres",
  },
  requireWorkplace: {
    defaultMessage: "Arbejdssted påkrævet",
  },
  showInLists: {
    defaultMessage: "Vis i lister",
  },
});

interface WorkTypeEditCardProps {
  workType: WorkType;
}

export const WorkTypeEditCard = React.memo(function WorkTypeEditCard(
  props: WorkTypeEditCardProps,
): JSX.Element {
  const {workType} = props;
  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleEditWorkTypeName = useCallback((): void => {
    setWorkTypeDialogOpen(true);
  }, []);

  const handleWorkTypeDialogOk = useCallback((_workTypeUrl: string): void => {
    setWorkTypeDialogOpen(false);
  }, []);

  const handleWorkTypeDialogCancel = useCallback((): void => {
    setWorkTypeDialogOpen(false);
  }, []);

  const handleColorSelected = useCallback(
    (color: string): void => {
      if (workType && workType.color !== color) {
        dispatch(actions.update(workType.url, [{member: "color", value: color}]));
      }
    },
    [dispatch, workType],
  );

  const handleActiveChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(workType.url, [{member: "active", value: checked}]));
    },
    [dispatch, workType.url],
  );

  const handleInternalTaskPrimaryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      dispatch(actions.update(workType.url, [{member: "internalTaskPrimary", value: checked}]));
    },
    [dispatch, workType.url],
  );

  const handleTowingCostMultiplierChange = useCallback(
    (value: number | null): void => {
      dispatch(actions.update(workType.url, [{member: "towingCostMultiplier", value}]));
    },
    [dispatch, workType.url],
  );

  const handleRequireMachineUseChange = useEventTargetCheckedCallback(
    (requireMachineUse: boolean) => {
      const patch: PatchOperation<WorkType>[] = [
        {member: "requireMachineUse", value: requireMachineUse},
      ];
      if (requireMachineUse) {
        patch.push({member: "disallowMachineUse", value: false});
      }
      dispatch(actions.update(workType.url, patch));
    },
    [dispatch, workType.url],
  );

  const handleEnableSmallMachinesChange = useEventTargetCheckedCallback(
    (enableSmallMachines: boolean) => {
      dispatch(
        actions.update(workType.url, [{member: "enableSmallMachines", value: enableSmallMachines}]),
      );
    },
    [dispatch, workType.url],
  );

  const handleAllowMaxOneMachineChange = useEventTargetCheckedCallback(
    (allowMaxOneMachine: boolean) => {
      const patch: PatchOperation<WorkType>[] = [
        {member: "allowMaxOneMachine", value: allowMaxOneMachine},
      ];
      if (allowMaxOneMachine) {
        patch.push({member: "disallowMachineUse", value: false});
      }
      dispatch(actions.update(workType.url, patch));
    },
    [dispatch, workType.url],
  );

  const handleDisallowMachineUseChange = useEventTargetCheckedCallback(
    (disallowMachineUse: boolean) => {
      const patch: PatchOperation<WorkType>[] = [
        {member: "disallowMachineUse", value: disallowMachineUse},
      ];
      if (disallowMachineUse) {
        patch.push({member: "allowMaxOneMachine", value: false});
        patch.push({member: "requireMachineUse", value: false});
      }
      dispatch(actions.update(workType.url, patch));
    },
    [dispatch, workType.url],
  );

  const handleRequirePhotoOnTaskCompletionChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requirePhotoOnTaskCompletion",
  );

  const handleRequireAttachmentChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requireAttachment",
  );

  const handleRequireWorkplaceChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requireWorkplace",
  );

  const handleRequireFieldChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requireField",
  );

  const handleRequireEffectiveTimeChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requireEffectiveTime",
  );

  const handleOnlyForExtraTimersChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "onlyForExtraTimers",
  );

  const handleRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroChange =
    useEventTargetCheckedUpdater<WorkType>(
      workType.url,
      "requireAtLeastOneOptionalPriceItemUseGreaterThanZero",
    );
  const handleRequireAtLeastOneProductUseGreaterThanZeroChange =
    useEventTargetCheckedUpdater<WorkType>(
      workType.url,
      "requireAtLeastOneProductUseGreaterThanZero",
    );

  const handleNoProductUsesOrAtLeastOneProductUseGreaterThanZeroChange =
    useEventTargetCheckedUpdater<WorkType>(
      workType.url,
      "noProductUsesOrAtLeastOneProductUseGreaterThanZero",
    );

  const handleRequireNotesFromMachineOperatorChange = useEventTargetCheckedUpdater<WorkType>(
    workType.url,
    "requireNotesFromMachineOperator",
  );

  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);

  const handleDepartmentSelectButton = useCallback((): void => {
    setDepartmentDialogOpen(true);
  }, []);

  const handleDepartmentDialogCancel = useCallback((): void => {
    setDepartmentDialogOpen(false);
  }, []);

  const handleDepartmentDialogOk = useCallback(
    (department: string): void => {
      setDepartmentDialogOpen(false);
      if (department !== workType.department) {
        dispatch(actions.update(workType.url, [{member: "department", value: department}]));
      }
    },
    [dispatch, workType.department, workType.url],
  );

  const handleClearDepartment = useCallback((): void => {
    if (workType.department) {
      dispatch(actions.update(workType.url, [{member: "department", value: ""}]));
    }
  }, [dispatch, workType.department, workType.url]);

  const intl = useIntl();

  const customerSettings = useSelector(getExtendedCustomerSettings);
  const currentRole = useSelector(getCurrentRole);

  const {
    workTypes: {canEditField: unboundCanEditField, canImportExternal, canOpenEditDialog},
  } = customerSettings;

  const canEditField = unboundCanEditField.bind(null, workType);

  const theme = useTheme();

  const {active, barred, identifier, name} = workType;

  let editButton;
  let editFields;

  const internal =
    !workType.externalTaskPrimary &&
    !(workType.externalTaskSecondary && workType.internalTaskSecondary);

  if (canOpenEditDialog(workType)) {
    editButton = (
      <IconButton style={{float: "right"}} onClick={handleEditWorkTypeName}>
        <PencilIcon />
      </IconButton>
    );
  }
  if (!hasExternalId(workType) || (hasExternalId(workType) && canImportExternal)) {
    editFields = (
      <div>
        <FormControlLabel
          control={<Switch checked={active} onChange={handleActiveChange} />}
          disabled={barred || !canEditField("active")}
          label={intl.formatMessage(messages.active)}
        />
      </div>
    );
  }
  let requireEffectiveTimeToggle: JSX.Element | null = null;
  let requireEffectiveTimeToggleConsultant: JSX.Element | null = null;
  if (!internal) {
    if (customerSettings.c5Sync) {
      requireEffectiveTimeToggleConsultant = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!!workType.requireEffectiveTime}
                onChange={handleRequireEffectiveTimeChange}
              />
            }
            disabled={!canEditField("requireEffectiveTime")}
            label={intl.formatMessage({
              defaultMessage: "Kræver effektiv tid. (Kontakt udvikler for opsætning i CO Sync)",
            })}
          />
        </div>
      );
    } else {
      requireEffectiveTimeToggle = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!!workType.requireEffectiveTime}
                onChange={handleRequireEffectiveTimeChange}
              />
            }
            disabled={!canEditField("requireEffectiveTime")}
            label={intl.formatMessage({
              defaultMessage: "Kræver effektiv tid",
            })}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <Card style={{marginBottom: 22}}>
        <CardContent>
          <div>
            <div style={{float: "left"}}>
              <h4>
                <FormattedMessage defaultMessage="ID" />
              </h4>
              {identifier}
            </div>
            {editButton}
            <div style={{clear: "both"}}>
              <h4>
                <FormattedMessage defaultMessage="Navn" />
              </h4>
              {name}
            </div>
          </div>
          <hr style={{border: "none", borderTop: "1px double"}} />
          {customerSettings.economicSync && workType?.barred ? (
            <div style={{color: theme.palette.warning.main}}>
              <FormattedMessage defaultMessage="Varegruppen er blevet slettet i e-conomic" />
            </div>
          ) : null}
          {editFields}
          {internal ? (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workType.internalTaskPrimary}
                    onChange={handleInternalTaskPrimaryChange}
                  />
                }
                disabled={!active}
                label={intl.formatMessage(messages.showInLists)}
              />
            </div>
          ) : null}
          <SelectColor
            color={workType.color}
            disabled={!canEditField("internalTaskPrimary")}
            onColorSelected={handleColorSelected}
          />
          {!internal && customerSettings.enableMachineAnalysis ? (
            <DecimalField
              fullWidth
              decimalPlaces={3}
              disabled={!canEditField("towingCostMultiplier")}
              label={intl.formatMessage({
                defaultMessage: "Multiplikator, bugseringsomkostning (effektiv)",
              })}
              margin="dense"
              maxDigits={5}
              style={{marginTop: "2em"}}
              value={workType.towingCostMultiplier}
              onChange={handleTowingCostMultiplierChange}
            />
          ) : null}
          {!internal ? (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      workType.requireAtLeastOneOptionalPriceItemUseGreaterThanZero !== false
                    }
                    onChange={handleRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroChange}
                  />
                }
                disabled={!canEditField("requireAtLeastOneOptionalPriceItemUseGreaterThanZero")}
                label={intl.formatMessage(
                  messages.requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
                )}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={workType.requireAtLeastOneProductUseGreaterThanZero !== false}
                    onChange={handleRequireAtLeastOneProductUseGreaterThanZeroChange}
                  />
                }
                disabled={!canEditField("requireAtLeastOneProductUseGreaterThanZero")}
                label={intl.formatMessage(messages.requireAtLeastOneProductUseGreaterThanZero)}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={workType.noProductUsesOrAtLeastOneProductUseGreaterThanZero !== false}
                    onChange={handleNoProductUsesOrAtLeastOneProductUseGreaterThanZeroChange}
                  />
                }
                label={intl.formatMessage(
                  messages.noProductUsesOrAtLeastOneProductUseGreaterThanZero,
                )}
              />
            </div>
          ) : null}
          {currentRole?.consultant || !customerSettings.c5Sync ? (
            <div
              style={
                customerSettings.c5Sync
                  ? {
                      backgroundColor: theme.palette.consultant.main,
                      marginTop: 5,
                      padding: 5,
                    }
                  : {
                      marginTop: 5,
                      padding: 5,
                    }
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workType.requireNotesFromMachineOperator}
                    onChange={handleRequireNotesFromMachineOperatorChange}
                  />
                }
                disabled={!canEditField("requireNotesFromMachineOperator")}
                label={intl.formatMessage({
                  defaultMessage: "Kræv at notefeltet er udfyldt ifm. udførelse af opgaver",
                })}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={workType.requireMachineUse !== false}
                    onChange={handleRequireMachineUseChange}
                  />
                }
                disabled={!canEditField("requireMachineUse")}
                label={intl.formatMessage(messages.requireMachineUse)}
              />
              <br />
              {customerSettings.enableSmallMachines ? (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={workType.enableSmallMachines !== false}
                        onChange={handleEnableSmallMachinesChange}
                      />
                    }
                    disabled={!canEditField("enableSmallMachines")}
                    label={intl.formatMessage({
                      defaultMessage: "Småmaskiner anvendes på dette område",
                    })}
                  />
                  <br />
                </>
              ) : null}
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workType.allowMaxOneMachine}
                    onChange={handleAllowMaxOneMachineChange}
                  />
                }
                disabled={!canEditField("allowMaxOneMachine")}
                label={intl.formatMessage({
                  defaultMessage: "Tillad kun én maskine på opgaven",
                })}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workType.disallowMachineUse}
                    onChange={handleDisallowMachineUseChange}
                  />
                }
                disabled={!canEditField("disallowMachineUse")}
                label={intl.formatMessage(messages.disallowMachineUse)}
              />
              <br />
              {!internal || customerSettings.internalTaskPhotos ? (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!workType.requirePhotoOnTaskCompletion}
                        onChange={handleRequirePhotoOnTaskCompletionChange}
                      />
                    }
                    disabled={!canEditField("requirePhotoOnTaskCompletion")}
                    label={intl.formatMessage(messages.requirePhotoOnTaskCompletion)}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!workType.requireAttachment}
                        onChange={handleRequireAttachmentChange}
                      />
                    }
                    disabled={!canEditField("requireAttachment")}
                    label={intl.formatMessage({
                      defaultMessage: "Kræver PDF eller foto når opgave udføres",
                    })}
                  />
                  <br />
                </>
              ) : null}
              {!internal ? (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!workType.requireWorkplace}
                        onChange={handleRequireWorkplaceChange}
                      />
                    }
                    disabled={!canEditField("requireWorkplace")}
                    label={intl.formatMessage(messages.requireWorkplace)}
                  />
                  <br />
                </>
              ) : null}
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workType.onlyForExtraTimers}
                    onChange={handleOnlyForExtraTimersChange}
                  />
                }
                disabled={!canEditField("onlyForExtraTimers")}
                label={intl.formatMessage(messages.onlyForExtraTimers)}
              />
              <br />
              {!internal ? (
                <FormControlLabel
                  control={
                    <Switch checked={!!workType.requireField} onChange={handleRequireFieldChange} />
                  }
                  disabled={!canEditField("requireField")}
                  label={intl.formatMessage(messages.requireField)}
                />
              ) : null}
              {requireEffectiveTimeToggle}
            </div>
          ) : null}

          {currentRole?.consultant ? (
            <div style={{backgroundColor: theme.palette.consultant.main}}>
              {requireEffectiveTimeToggleConsultant}
            </div>
          ) : null}
          {(customerSettings.enableExternalTaskDepartmentField && workType.externalTaskPrimary) ||
          (customerSettings.enableInternalTaskDepartmentField && !workType.externalTaskPrimary) ? (
            <div>
              <h4>
                <FormattedMessage defaultMessage="Auto-afdeling" />
              </h4>
              <Button color="primary" variant="contained" onClick={handleDepartmentSelectButton}>
                <FormattedMessage defaultMessage="Vælg" />
              </Button>
              <br />
              {getDepartmentName(workType.department || "", customerSettings)}
              <IconButton onClick={handleClearDepartment}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
        </CardContent>
      </Card>
      <ConnectedDepartmentDialog
        open={departmentDialogOpen}
        onCancel={handleDepartmentDialogCancel}
        onOk={handleDepartmentDialogOk}
      />
      {internal ? (
        <WorkTypeCreateEditInternalDialog
          open={workTypeDialogOpen}
          workType={workType}
          onCancel={handleWorkTypeDialogCancel}
          onOk={handleWorkTypeDialogOk}
        />
      ) : (
        <WorkTypeCreateEditDialog
          open={workTypeDialogOpen}
          workType={workType}
          onCancel={handleWorkTypeDialogCancel}
          onOk={handleWorkTypeDialogOk}
        />
      )}
    </div>
  );
});
